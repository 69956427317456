<template>
  <div>
    <standbyDialog :localLoadingDialog="localLoadingDialog"></standbyDialog>
    <primaryButton
        class="mr-2"
        :attributesArray="functionElement['attributesArray']"
        type="button"
        :value="functionElement['functionName']"
        @click="restart"
        :label="functionElement['functionName']"
        color="save"
        :disabled="loading"
        :loading="loading"
        icon="mdi-move-resize-variant"
        :shrink="functionElement['shrink']"></primaryButton>
  </div>
</template>
<script>
import primaryButton from '@/commonComponents/primaryButton.vue';
import standbyDialog from '@/commonComponents/standbyDialog.vue';

export default {
  props: ['isValid', 'loading', 'functionElement', 'result'],
  components: {
    primaryButton,
    standbyDialog,
  },
  data() {
    return {
      localLoadingDialog: false,
    };
  },
  created() {
    // this.$parent.$root.getFilteredRow(
    //   {
    //     pre: false,
    //     post: false,
    //     condition: true,
    //   },
    //   this
    // );
  },
  methods: {
    restart() {
      this.localLoadingDialog = true;
      //  let caller = this;

      let project = this.result.valueLine.projects;
      let organisations = this.result.valueLine.organisations;

      const options = {
        function: 'switchProjectAjax',
        requestType: 'ajax',
        project: project,
        organisations: organisations,
      };
      const sentURI = 'serve.php?f=administration&f2=projectAdministration';
      //showLoading();
      let caller = this;
      this.frameworkAxiosRequest({
        method: 'post',
        url: sentURI,
        data: options,
      }).then(function (response) {
        caller.postRequest(`${process.env.VUE_APP_AUTH_SERVICE}/auth-service/v1/context-switch`, {
          project: parseInt(project),
          organisation: parseInt(organisations),
        }, (jwt) => {
          caller.projectSwitch = false;
          caller.storeJwt(jwt);
          caller.frameworkPostRequest('?f=global&f2=login', 'saveAccessToken',
              {accessToken: jwt.access_token},
              () => {
                caller.updateProjectState(() => {
                  if (typeof response.data.result['redirect'] != 'undefined') {
                    window.location.href = response.data.result['redirect'];
                  } else {
                    location.reload();
                  }
                });
              });
        });
      }).catch(function (response) {
        //handle error
        console.log(response);
      });
    },
  },
};
</script>